<template>
  <div class="wrapper">
    <!-- <img src="/img/INFORMATIVO_epublic.png" width="100%" /> -->
    <div
      class="avisoNavegador"
      :style="estilosDinamicos"
      v-if="!ocultarAvisoNavegador && browserName != 'chrome'"
    >
      <v-row>
        <v-col>
          <v-alert color="warning" text>
            <h3 class="text-h5 avisoColorLink text-center">
              <strong style="font-size: 15pt"> ATENÇÃO </strong>
            </h3>
            <div>
              <v-row class="mt-3">
                <v-col cols="12">
                  <strong class="avisoColorLink">
                    Identificamos que o navegador, em uso, não é o chrome. <br />
                    Para melhor experiência, em nosso sistema, recomendamos seu uso.
                  </strong>
                  <!-- {{ browserName }} -->
                </v-col>
              </v-row>
            </div>

            <v-divider class="my-4 info" style="opacity: 0.22"></v-divider>

            <v-row align="center" no-gutters>
              <v-row class="mt-3">
                <v-col cols="12">
                  <a :href="linkDownload" target="__blank" class="avisoColor">
                    O download pode ser feito clicando aqui
                    <img src="/img/google-chrome-icon-1.png" width="25"
                  /></a>
                </v-col>
              </v-row>

              <v-spacer></v-spacer>
              <v-col class="shrink">
                <v-btn color="warning" outlined @click="ocultarAvisoNavegador = true">
                  Entendi
                </v-btn>
              </v-col>
            </v-row>
          </v-alert>
        </v-col>
      </v-row>
    </div>

    <v-card class="login-card" :style="estilosDinamicosCardLogin">
      <v-card-title class="d-flex justify-center">
        <img src="/img/logo.png" width="100" />
      </v-card-title>
      <v-card-text>
        <ValidationObserver v-slot="{ handleSubmit }">
          <v-form ref="form" @submit.prevent="handleSubmit(logar)">
            <ValidationProvider name="Login de acesso" rules="required" v-slot="{ errors }">
              <v-text-field
                v-model="credentials.email"
                filled
                prepend-inner-icon="mdi-account-outline"
                label="Login de acesso"
                :error-messages="errors"
                required
              />
            </ValidationProvider>
            <ValidationProvider name="Senha" rules="required" v-slot="{ errors }">
              <v-text-field
                v-model="credentials.password"
                filled
                prepend-inner-icon="mdi-lock-outline"
                label="Senha"
                :type="showPassword ? 'text' : 'password'"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :error-messages="errors"
                required
                @click:append="showPassword = !showPassword"
              />
            </ValidationProvider>
            <v-btn
              block
              color="primary"
              type="submit"
              :class="loginButtonClass"
              :disabled="logando"
              :loading="logando"
            >
              Entrar no sistema
            </v-btn>
          </v-form>
        </ValidationObserver>
        <a
          @click="$router.push({ name: 'resetPassword' })"
          target="__blank"
          class="help-section d-flex flex-column align-center"
          v-bind:class="{ 'animate__animated animate__headShake': animarAjuda }"
        >
          <span class="mt-4">Esqueci minha senha!</span>
        </a>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "LoginPage",
  data() {
    return {
      credentials: { email: "", password: "" },
      showPassword: false,
      logando: false,
      loginButtonClass: "",
      animarAjuda: false,
      browserName: "",
      linkDownload:
        "https://www.google.com/chrome/dr/download/?brand=JJTC&ds_kid=43700077663103092&gclid=EAIaIQobChMIi7SfmN6ygwMVeV9IAB0DFAJtEAAYASAAEgKu2_D_BwE&gclsrc=aw.ds",
      ocultarAvisoNavegador: false,
    };
  },
  mounted() {
    // this.$Tawk.$setOnLoadFunction(() => this.$Tawk.$hideWidget());
    // this.$Tawk.$hideWidget();
    this.fnBrowserDetect();
    const switchAnimarAjuda = () => {
      this.animarAjuda = !this.animarAjuda;
      setTimeout(() => switchAnimarAjuda(), 2000);
    };
    switchAnimarAjuda();
  },
  computed: {
    estilosDinamicos() {
      return {
        marginTop: !this.ocultarAvisoNavegador ? "5%" : "80%",
        marginBottom: this.browserName == "safari" ? "4%" : "1%",
      };
    },
    estilosDinamicosCardLogin() {
      if (this.browserName == "chrome") {
        return {
          marginTop: "10%",
        };
      }
      return {
        marginTop: !this.ocultarAvisoNavegador ? "0%" : "10%",
      };
    },
  },
  methods: {
    fnBrowserDetect() {
      const { userAgent } = navigator;
      console.log(userAgent);

      if (userAgent.match(/chrome|chromium|crios/i) && !userAgent.includes("OPR")) {
        this.browserName = "chrome";
      } else if (userAgent.match(/firefox|fxios/i) && !userAgent.includes("OPR")) {
        this.browserName = "firefox";
      } else if (userAgent.match(/safari/i) && !userAgent.includes("OPR")) {
        this.browserName = "safari";
      } else if (userAgent.match(/opr/i) || userAgent.includes("OPR")) {
        this.browserName = "opera";
      } else if (userAgent.match(/edg/i)) {
        this.browserName = "edge";
      } else {
        this.browserName = "No browser detection";
      }
    },
    async logar() {
      this.logando = true;
      try {
        await this.$services.authService.logar(this.credentials);
        this.$toast.success("Logado no sistema com sucesso!");
        const user = await this.$services.authService.getCurrentUser();
        const { name, redirect } = this.$route.query;
        let has_permission = false;
        user.permissions.forEach((e) => {
          if (name === e || `${name}.*` === e || `${name?.split(".")[0]}.atualizar` === e) {
            has_permission = true;
          }
        });
        if (redirect && has_permission) this.$router.push(redirect);
        else this.$router.push({ name: "home" });
      } catch (error) {
        this.$toast.danger(error.error.message);
        this.loginButtonClass = "animate__animated animate__headShake";
        setTimeout(() => (this.loginButtonClass = ""), 500);
      }
      this.logando = false;
    },
  },
};
</script>

<style scoped>
.wrapper {
  height: 100vh;
  width: 100vw;
  position: absolute;
  background-image: url("../../assets/background_tratato_para_web.jpg");
  background-size: cover;
}

.wrapper > .login-card {
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80vw;
  max-width: 600px;
  background-color: rgba(255, 255, 255, 0.7);
}

.help-section > i {
  margin-top: 12px;
  margin-bottom: 4px;
  margin-left: 50%;
  margin-right: 50%;
  transform: translateX(-50%);
}

.help-section {
  text-decoration: none;
  color: inherit;
  font-weight: 500;
}
.avisoNavegador {
  margin-top: 5%;
  width: 500px !important;
  margin-left: 50%;
  transform: translateX(-50%);
}
.avisoColor {
  color: rgb(237, 147, 22) !important;
  font-size: 12pt;
}
.avisoColorLink {
  color: rgb(108, 107, 105) !important;
  font-size: 12pt;
}
</style>
